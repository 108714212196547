let baseURL = '';
let baseFileURL = '';
let UPLOAD_ACTION = '';
let HKYURL = '';
if(process.env.NODE_ENV == 'development'){
    baseURL = location.protocol + '//' + location.host + '/recordbook/';
    HKYURL = location.protocol + '//' + location.host + '/hky/';
    baseFileURL = location.protocol + '//' + location.host + '/mircodata/downloadbyfastdfspath?fastdfspath=';
    UPLOAD_ACTION = location.protocol + '//' + location.host + '/mircodata/uploadfile';
}else{
    baseURL = location.protocol + '//' + location.host + '/recordbook/';
    HKYURL = location.protocol + '//' + location.host + '/hky/';
    baseFileURL = location.protocol + '//' + location.host + '/mircodata/downloadbyfastdfspath?fastdfspath=';
    UPLOAD_ACTION = location.protocol + '//' + location.host + '/mircodata/uploadfile';
}
export {baseURL,baseFileURL,UPLOAD_ACTION,HKYURL};