<template>
    <div class="ApplicationHeader">
            <div class="back_warp" v-if="back">
                <slot name="back">
                    <div @click="backHome" style="display:flex;align-items:center;">
                        <el-icon :name="backIcon" class="_icon" ></el-icon>
                        <div class="back_text">{{backText}}</div>
                    </div>
                    <div class="back-subline"></div>
                </slot>
            </div>
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name:'XHeader',
        props:{
            back:Boolean,
            backText:{
                type:String,
                default:'返回'
            },
            backIcon:{
                type:String,
                default:'back'
            },
			customBack:Boolean
        },
        data(){
            return {

            }
        },
        methods:{
            backHome(){
				if(this.customBack){
					this.$emit('back')
					return;
				}
                this.$router.go(-1)
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '../base/ui/style/var.scss';
    .ApplicationHeader{width: 100%;height: 40px;font-weight: 600;padding: 10px 20px;background: white;box-sizing: border-box;display: flex;align-items: center;}
    .ApplicationHeader .back_warp{width: auto;height: 56px;display: flex;align-items: center;overflow: hidden;margin-right:16px;font-weight: normal;font-size: 13px;color: $--color-text-secondary;}
    .ApplicationHeader .back_warp ._icon{cursor: pointer;}
    .ApplicationHeader .back_warp .back_text{margin-left: 8px;cursor: pointer;}
    .ApplicationHeader .back_warp .back-subline{height: 16px;width: 1px;background: #dcdfe6;margin-left: 16px;}
</style>