export const MOBILE_CLIENT_WIDTH = 576
export const PAGE_HEADER = {
    PAGE_INDEX:'PAGE_INDEX',
    PAGE_DATA_SEARCH:'PAGE_DATA_SEARCH',
    PAGE_INS_BOARD:'PAGE_INS_BOARD',
    PAGE_KEY_AREA:'PAGE_KEY_AREA',
    PAGE_PERCEPT:'PAGE_PERCEPT'
}
export const STORAGE = {
    USER:'login_user',
    TOKEN:'login_token',
    ROLE:'login_role',
    PERMISSION:'login_permission'
}
export const PERMISSION = {
    CONSOLE_MANAGER:'console:manager',
    USER_MANAGER:'user:manager',
    USER_EDIT:'user:edit',
    ROLE_MANAGER:'role:manager',
    ROLE_EDIT:'role:edit',
    GROUP_MENU:'group:menu',
    GROUP_CREATE:'group:create',
    EXPERIMENT_RETRIEVAL:'experiment:retrieval',
    GROUP_MANAGER:'group:manager',
    EXPERIMENT_CHECK:'experiment:check',
    EXPERIMENT_SHARE:'experiment:share',
    EXPERIMENT_CREATE:'experiment:create',
    ROLE_RECYCLE:'role:recycle',
    USER_TEMPLATE:'user:template',
    EXPERIMENT_AUDIT:'experiment:audit',
    AUDIT_GROUP:'audit:group',
    AUDIT_SEARCH:'audit:search',
    DOMAIN_CONFIG:'domain:config',
    EXPERIMENT_MANAGER:'experiment:manager',
    TEMPLATE_CLAIM:'template:claim',
    GROUP_FILE:'group:file',
    GROUP_OTHER:'group:other',
    INSTITUTE_EDIT:'institute:edit',
    INSTITUTE_MANAGER:'institute:manager',
    AUDITUSE_MANAGER:'auditUse:manager',
    LOG_MANAGER:'log:manager',

}

//系统角色
export const SYSTEM_ROLE = {
    ADMIN:'admin',
    MANAGER:'manager',
    SHENJI:'shenji',
    CONSUMER:'consumer',
    SUPER_ADMIN:'superadmin'
}
export const SYSTEM_ROLE_NAME = {
    'admin':'系统管理员',
    'manager':'研究所管理员',
    'consumer':'普通用户',
    'superadmin':'--',
    'shenji':'研究所审计员'
}

export const GROUP_ROLE = {
    LEADER:'1',
    MANAGER:'2',
    USER:'3',
    REVIEW:'4'
}
//未分类Id
export const UNSORTED_ID = '7280e9ef5cff1a6aa2b6f167fee2d074'
export const SHOW_GROUP_ROLE = {
    '1':'项目负责人',
    '2':'项目负责人',
    '3':'普通用户',
    '4':'项目复核人',
    '24':'项目复核人,项目负责人',
    '42':'项目复核人,项目负责人',
}
export const CACHE_KEY = {
    LOGIN_TO_URL:'login_to_url'
}
export const FORM_EXPERIMENT_FIELD_TEMPLATE =  ['subject',"annex",'editerHtml']
export const FORM_EXPERIMENT_FIELD =  ['subject',"annex","keyword_tags"]//痕迹比对使用

export const  HKY_PRIVEW_URL = "https://file.scholarin.cn/files?fastdfspath=";
//慧科研下载默认路径
export const  HKY_DOWLOAD_URL = "https://data.scholarin.cn/api/file/downloadFile?token=1&fileId=";