import Vue from 'vue'
import Vuex from 'vuex'
import {SET_USER,SET_TOKEN,SET_ROLE,SET_PERMISSION} from './types'
import {GetStorage} from '../util/cache'
import {STORAGE} from '../constants'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:GetStorage(STORAGE.USER),
    roles:GetStorage(STORAGE.ROLE),
    permissons:GetStorage(STORAGE.PERMISSION),
    token:GetStorage(STORAGE.TOKEN)
  },
  mutations: {
    [SET_USER](state,user){
      state.user = user
    },[SET_TOKEN](state,token){
      state.token = token
    },[SET_ROLE](state,roles){
      state.roles = roles
    },[SET_PERMISSION](state,permissons){
      state.permissons = permissons
    }
  },
  actions: {
  },
  modules: {
  }
})
