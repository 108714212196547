import http from '../util/http.js'

export const List = (searchText,noteId,groupId,page,pageSize = 10,sortType,sort) => http.get('/experiment/list',{searchText,noteId,groupId,page,pageSize,sortType,sort})
export const TypeList = (searchText,noteId,groupId,page,pageSize = 10,sortType,sort) => http.get('/experiment/typeList',{searchText,noteId,groupId,page,pageSize,sortType,sort})
export const Update = (experimentRecordBook) => http.postJSON('/experiment/update',experimentRecordBook)
export const ReviewExperiment = (experimentRecordBook) => http.postJSON('/experiment/reviewExperiment',experimentRecordBook)
export const Save = (experimentRecordBook) => http.postJSON('/experiment/save',experimentRecordBook)
export const Delete = (uuid) => http.post('/experiment/delete',{uuid:uuid})
export const Submit = (uuid,groupId,signature) => http.post('/experiment/submit',{uuid,groupId,signature})
export const GetDetail = (uuid) => http.get('/experiment/getDetail',{uuid})
export const ListMark = (experimentUuid) => http.get('/experiment/listMark',{experimentUuid})
export const GetReviewList = (status,page,pageSize = 10) => http.get('/experiment/getReviewList',{status,page,pageSize})
export const GetShareList = (share,groupId,page,pageSize = 10) => http.get('/experiment/getShareList',{share,groupId,page,pageSize})
export const ExportExcel = (uuid) => http.get('/experiment/exportExcel',{uuid})
export const ExportWordOrPdf = (uuid,type) => http.get('/experiment/exportWordOrPdf',{uuid,type})
export const GetDeleteList = (page,pageSize = 10) => http.get('/experiment/getDeleteList',{page,pageSize})
export const CancelDelete = (uuid) => http.get('/experiment/cancelDelete',{uuid})
export const FallbackVersion = (id,experimentRecordBook) => http.postJSON('/experiment/fallbackVersion?id=' + id,experimentRecordBook)
export const SearchExperiment = (userName,fullTextSearch,searchText,experimentId,groupId,status,share,startDate,endDate,sort,sortType,page,pageSize = 10) => http.get('/experiment/searchExperimentList',{userName,fullTextSearch,searchText,experimentId,groupId,status,share,startDate,endDate,sort,sortType,page,pageSize})

export const GetExperimentByCategory = (typeId,groupId) => http.get('/experiment/getExperimentByTypeId',{typeId,groupId})

export const GetAuditNumData = () => http.get("/experiment/getAuditNumData")
export const UpdateProjectId = (uuid,projectId) => http.post('/experiment/updateProjectId',{uuid,projectId})
export const GetTobeReviewedCount = () => http.get("/experiment/getTobeReviewedCount")
export const GetCertificate = (txid) => http.get('/experiment/getCertificate',{txid})









