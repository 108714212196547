<template>
    <div class="warp" :class="{'header_fixed':headerFixed}">
        <div class="content" :style="{minHeight:footer?layoutMinHeight():'',flexDirection:row?'row':'column',...contentStyle}">
            <slot></slot>
        </div>
        <x-footer v-if="footer">
			资助机构：中国科学院人事局
			&nbsp;&nbsp;&nbsp;
			开发维护：中国科学院文献情报中心
		</x-footer>
    </div>
</template>
<script>
    import XFooter from './XFooter.vue'
	import {AppMixins} from "@/mixins";
    export default {
        name:'XContent',
		mixins:[AppMixins],
        components: { XFooter },
        data(){
            return {

            }
        },
        props:{
            headerFixed:{
                type:Boolean,
                default:false
            },
            footer:{
                type:Boolean,
                default:false
            },
            row:{
                type:Boolean,
                default:false
            },
            contentStyle:Object
        }
    }
</script>
<style lang="scss" scoped>
    .warp{width: 100%;height: auto;overflow: hidden;}
    .content{width: 1200px;height: auto;overflow: hidden;margin: 0 auto;display: flex;flex-wrap: wrap;}
    .header_fixed{margin-top: calc(60px + 8px);}
</style>