<template>
  <span :style="{ width: size + 'px', 'min-width': size + 'px', height: size + 'px', 'border-radius': size > 34 ? '4px' : '2px' }" style="overflow: hidden;display: block;">
    <el-image v-if="url" class="Avatar" :class="{'Avatar--round' : round, 'Avatar--large': size > 34, 'Avatar--pointer' : pointer}" :width="size" :height="size" :src="url">
       <div slot="error" class="image-slot">
          <img v-if="size <= 34" class="Avatar" :class="{'Avatar--round' : round, 'Avatar--pointer' : pointer}" :width="size" :height="size" src="../../assets/avatar_placeholder.png">

          <img v-else class="Avatar Avatar--large" :class="{'Avatar--round' : round, 'Avatar--pointer' : pointer}" :width="size" :height="size" src="../../assets/avatar_placeholder_large.png">

        </div>
    </el-image>
    <img v-else-if="size <= 34" class="Avatar" :class="{'Avatar--round' : round, 'Avatar--pointer' : pointer}" :width="size" :height="size" src="../../assets/avatar_placeholder.png" />
    <img v-else class="Avatar Avatar--large" :class="{'Avatar--round' : round, 'Avatar--pointer' : pointer}" :width="size" :height="size" src="../../assets/avatar_placeholder_large.png" />
  </span>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    url: null,
    size: 0,
    round: {
      type: Boolean,
      default: false
    },
    pointer: {
      type: Boolean,
      default: true
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "./ui/style/var";
  .Avatar {
    background: $--color-white;
    border-radius: 2px;
    object-fit: cover;
	  width: 100%;
	  height: 100%;
  }
  .Avatar--pointer {
    cursor: pointer;
  }
  .Avatar--large {
    border-radius: $--border-radius-base;
  }
  .Avatar--round {
    border-radius: 50%;
  }
</style>
