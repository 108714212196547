import http from './http'
import {SetStorage,GetStorage,RemoveStorage} from './cache'
import {CACHE_KEY} from '../constants'
import {domainConfigDataByDomain} from "./../service/domainConfig"

import {appid,secret,redirectUri,quitRedirectUri,institutionId} from '../main'
export const getAccessToken = (code,callBack) => {
    if(code){
        http.get(`https://scholarin.cn/hky/oauth/access_token?appid=${appid}&secret=${secret}&code=${code}&grant_type=authorization_code`).then(resp => {
            if(resp.failure){
                alert('登录失败,请重试')
                location.href = '/'
            }else{
                getUserInfo(resp.user_id,resp.access_token,callBack);
            }
        }).catch(e => {
            console.error(e)
        })
    }
}
export const getUserInfo = (userId,accessToken,callBack) => {
    http.get(`https://scholarin.cn/hky/scholars/${userId}/base?access_token=${accessToken}`).then(e => {
    e.currInstitutionId=institutionId
        callBack && callBack(e)
    }).catch(e => {
        console.error(e)
    });
}

export const toLogin = () => {
    SetStorage(CACHE_KEY.LOGIN_TO_URL,location.href)
    location.href = `https://scholarin.cn/oauth/authorize?appid=${appid}&redirect_uri=${redirectUri}&scope=api_signin&response_type=code&state=STATE&oauth_type=email`
}

export const outLogin = () => {
    RemoveStorage(CACHE_KEY.LOGIN_TO_URL)
    var url = encodeURIComponent(`https://scholarin.cn/hky/oauth/logout?appid=${appid}&redirect_uri=${quitRedirectUri}`);
    location.href = `https://passport.escience.cn/logout?WebServerURL=${url}`
}

function getUrlParam(str, def) {
    var query = window.location.search.substring(1);
    query = decodeURIComponent(query);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == str) { return pair[1]; }
    }
    return def || '';
}