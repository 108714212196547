<template>
    <div class="footer">
        <slot></slot>
    </div>
</template>
<script>
    export default {
        name:'XFooter'
    }
</script>
<style lang="sass" scoped>
    @import '../base/ui/style/var.scss';
    .footer{width: 100%;height: 40px;background-color: $--color-primary-light-2;line-height: 40px;text-align: center;color: white;margin-top:8px;}
</style>