var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.enableRipple),expression:"enableRipple"}],staticClass:"base-button",class:[
    _vm.type ? 'base-button--' + _vm.type : '',
    _vm.buttonSize ? 'base-button--' + _vm.buttonSize : '',
    {
      'is-disabled': _vm.buttonDisabled,
      'is-loading': _vm.loading,
      'is-plain': _vm.plain,
      'is-round': _vm.round,
      'is-circle': _vm.circle,
      'is-light': _vm.light
    }
  ],attrs:{"disabled":_vm.buttonDisabled || _vm.loading,"autofocus":_vm.autofocus,"type":_vm.nativeType},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('span',[_c('i',{staticClass:"base-icon-loading"})]):_vm._e(),(_vm.icon && !_vm.loading)?_c('span',[_c('i',{class:_vm.icon})]):_vm._e(),(_vm.$slots.default)?_c('span',[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }