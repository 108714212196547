var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-form-item",class:[{
    'base-form-item--feedback': _vm.baseForm && _vm.baseForm.statusIcon,
    'is-error': _vm.validateState === 'error',
    'is-validating': _vm.validateState === 'validating',
    'is-success': _vm.validateState === 'success',
    'is-required': _vm.isRequired || _vm.required,
    'is-no-asterisk': _vm.baseForm && _vm.baseForm.hideRequiredAsterisk
  },
  _vm.sizeClass ? 'base-form-item--' + _vm.sizeClass : ''
]},[_c('label-wrap',{attrs:{"is-auto-width":_vm.labelStyle && _vm.labelStyle.width === 'auto',"update-all":_vm.form.labelWidth === 'auto'}},[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"base-form-item__label",style:(_vm.labelStyle),attrs:{"for":_vm.labelFor}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label + _vm.form.labelSuffix))]})],2):_vm._e()]),_c('div',{staticClass:"base-form-item__content",style:(_vm.contentStyle)},[_vm._t("default"),_c('transition',{attrs:{"name":"fade-in-linear"}},[(_vm.validateState === 'error' && _vm.showMessage && _vm.form.showMessage)?_vm._t("error",function(){return [_c('div',{staticClass:"base-form-item__error",class:{
            'base-form-item__error--inline': typeof _vm.inlineMessage === 'boolean'
              ? _vm.inlineMessage
              : (_vm.baseForm && _vm.baseForm.inlineMessage || false),
            'is-required': _vm.isValueRequired
          },on:{"click":_vm.removeErrorMask}},[_vm._v(" "+_vm._s(_vm.validateMessage)+" ")])]},{"error":_vm.validateMessage}):_vm._e()],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }