import http from '../util/http.js'

export const LoginPlatform = (user) => http.postJSON('/user/login',user)
export const OutLoginPlatform = () => http.get('/user/outLogin')
export const GetAllUser = (params) => http.get('/user/getAllUser',params);
export const GetUnauditedUser = (params) => http.get('/user/getUnauditedUser',params);
export const AuditUser = (params) => http.post("/user/auditUser",params)

export const FindDataWorker = (name,page = 1,pageSize = 5) => http.get('/user/findDataWorker',{name,page,pageSize});
export const GetRoles = () => http.get("/role/list")
export const GetPermissions = () => http.get("/permission/list")
export const UpdateUserRole = (userId,roleId) => http.post("/user/updateUserRole",{userId:userId,roleId:roleId})
export const UpdateUserIns = (userId,insId) => http.post("/user/updateUserIns",{userId:userId,roleId:insId})
export const ClearUser = () => http.get("/user/clearUser")

export const SaveRole = (name,code) => http.post("/role/saveRole",{name:name,code:code})
export const UpdateRolePermission = (roleId,permissionIds) => http.post("/role/updateRolePermission",{roleId:roleId,permissionIds:permissionIds})
export const SaveGroup = (group) => http.post("/group/saveGroup",group)
export const UpdateGroup = (group) => http.post("/group/updateGroup",group)
export const UpdateGroupData = (group) => http.post("/group/updateGroupData",group)
export const ListGroup = (role,text,page = 1,pageSize = 10) => http.get("/group/listGroup",{role,text,page,pageSize})
export const GetGroupUser = (groupId,text,page = 1,pageSize = 10) => http.get("/groupUser/getGroupUser",{groupId,text,page,pageSize})
export const DissolutionGroup = (groupId) => http.get("/group/dissolutionGroup",{groupId})
export const ArchivingProject = (groupId) => http.get("/group/archivingProject",{groupId})
export const ExitProject = (groupId) => http.get("/group/exitProject",{groupId})
export const GetGroupById = (id) => http.get("/group/getGroupById",{id})
export const GetShareList = (groupId) => http.get("/share/shareList",{groupId})
//获取我的所有项目
export const GetMyProjectList = () => http.get("/group/getMyProjectList")
//获取我的不包括已归档项目，如果项目为空返回未分类项目
export const GetMyProjectData = () => http.get("/group/getMyProjectData")
export const GetAllUserToAddGroup = (insName) => http.get("/groupUser/getAllUser",{insName})
export const RemoveById = (id) => http.get("/groupUser/delete",{id})
export const GetDissolutionGroupUser = (groupId) => http.get("/groupUser/getDissolutionGroupUser",{groupId})
export const SaveOrUpdateList = (groupUserRelationList) => http.postJSON('/groupUser/saveOrUpdateList',groupUserRelationList)
export const Update = (groupUserRelation) => http.postJSON('/groupUser/update',groupUserRelation)
export const GetManageNum = (groupId) => http.get("/groupUser/getManageNum",{groupId})
export const TransferGroup = (groupId,userId,userName) => http.post("/group/transferGroup",{groupId:groupId,userId:userId,userName:userName})
export const GetUserRoleInGroup = (groupId) => http.get("/groupUser/getUserRole",{groupId})
export const GetGroupIdByUserId = () => http.get("/groupUser/getGroupIdByUserId",{})
export const GetFileV2 = (fileId, fileName) => { location.href = 'https://scholarin.cn/hky/api/v2/files?file_id=' + fileId + '&file_name=' +  fileName }
export const GetGroup = (isAll) => http.get("/group/getGroup",{isAll})
export const GetAllGroupById = (id) => http.get('/group/getAllGroupById',{id})
export const GetAllGroupUser = (groupId) => http.get("/groupUser/getAllGroupUser",{groupId})
export const ShareGroupOrUser = (expertimentId, groupId, shareUserId) => http.post("/share/shareGroupOrUser",{expertimentId:expertimentId,groupId:groupId,shareUserId:shareUserId})
export const GetDeleteList = (page,pageSize = 10) => http.get('/group/getDeleteList',{page,pageSize})
export const CancelDelete = (id) => http.get('/group/cancelDelete',{id})
export const GetUserDetails = () => http.get('/user/getUserDetails',{})
export const UpdateUser = (user) => http.postJSON('/user/updateUser',user)
export const ShareExperiment = (expertimentId,shareUserId) => http.post("/share/shareExperiment",{expertimentId:expertimentId,shareUserId:shareUserId})
export const InviteUser = (groupId,inviteEmail,groupName) => http.post("/groupUser/inviteUser",{groupId:groupId,inviteEmail:inviteEmail,groupName:groupName})
export const GetExUsers = () => http.get('/user/getExUsers',{})





