<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
    import { GetRoles } from "./service/user"
    export default {
      	name:'App',
		data(){
          return {

          }
        },
        created(){
            //获取角色信息填充角色没有权限访问的页面 
            //先注释掉，使用默认的，后续需要再行解开
            // GetRoles().then(e => {
            //   let data = this.APIParse(e);
            //   data.forEach(element => {
            //       this.roleNotPage[element.code] = element?.notPermissionPage?.split(';') || []
            //   });
            // })
        }
    }
</script>

<style lang="scss">
  @import './components/base/ui/style/index.scss';
  *::-webkit-scrollbar
  {
      width: 8px;
      height: 8px;
      background-color: #ffffff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  *::-webkit-scrollbar-track
  {
      //-webkit-box-shadow: inset 0 0 9px rgba(0,0,0,0.3);
     border-radius: 10px;
     background-color: transparent;
     // background-color: #F5F5F5; //transparent
  }

  /*定义滑块 内阴影+圆角*/
  *::-webkit-scrollbar-thumb
  {
      border-radius: 10px;
      background-color: transparent;
     // -webkit-box-shadow: inset 0 0 9px rgba(0,0,0,.3);
      //background-color: #bfbfbf;
  }
  html,body{width: 100%;height: 100%;padding: 0px;margin: 0px;    color: rgba(0,0,0,.85);}
  #app{width: 100%;height: 100%;}
  input{
    outline: none !important;
 
  }
  .mySign {
        border: 1px dashed #000;
    }
</style>