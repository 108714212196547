export const SetStorage = (key, value, expire = 7200000) => {
    let data = {
        value: value,
        expire: expire,
        timestamp: Date.now()
    }
    window.localStorage.setItem(key, JSON.stringify(data))
}

export const GetStorage = key => {
    let value = window.localStorage.getItem(key)
    if (!value) {
        return null
    }
    let data = JSON.parse(value)
    if (Date.now() > data.expire + data.timestamp) {
        window.localStorage.removeItem(key)
        return null
    } else {
        return data.value
    }
}

export const RemoveStorage = key => {
    window.localStorage.removeItem(key)
}