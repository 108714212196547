var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-progress",class:[
    'base-progress--' + _vm.type,
    _vm.status ? 'is-' + _vm.status : '',
    {
      'base-progress--without-text': !_vm.showText,
      'base-progress--text-inside': _vm.textInside,
    }
  ],attrs:{"role":"progressbar","aria-valuenow":_vm.percentage,"aria-valuemin":"0","aria-valuemax":"100"}},[(_vm.type === 'line')?_c('div',{staticClass:"base-progress-bar"},[_c('div',{staticClass:"base-progress-bar__outer",style:({height: _vm.strokeWidth + 'px'})},[_c('div',{staticClass:"base-progress-bar__inner",style:(_vm.barStyle)},[(_vm.showText && _vm.textInside)?_c('div',{staticClass:"base-progress-bar__innerText"},[_vm._v(_vm._s(_vm.content))]):_vm._e()])])]):_c('div',{staticClass:"base-progress-circle",style:({height: _vm.width + 'px', width: _vm.width + 'px'})},[_c('svg',{attrs:{"viewBox":"0 0 100 100"}},[_c('path',{staticClass:"base-progress-circle__track",style:(_vm.trailPathStyle),attrs:{"d":_vm.trackPath,"stroke":"#e5e9f2","stroke-width":_vm.relativeStrokeWidth,"fill":"none"}}),_c('path',{staticClass:"base-progress-circle__path",style:(_vm.circlePathStyle),attrs:{"d":_vm.trackPath,"stroke":_vm.stroke,"fill":"none","stroke-linecap":_vm.strokeLinecap,"stroke-width":_vm.percentage ? _vm.relativeStrokeWidth : 0}})])]),(_vm.showText && !_vm.textInside)?_c('div',{staticClass:"base-progress__text",style:({fontSize: _vm.progressTextSize + 'px'})},[(!_vm.status)?[_vm._v(_vm._s(_vm.content))]:_c('i',{class:_vm.iconClass})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }