import http from '../util/http.js'

export const List = (name,domainId,page,pageSize = 10) => http.get('/formtemplate/list',{name,domainId,page,pageSize})
export const Detail = (id) => http.get('/formtemplate/detail',{id})
export const Delete = (id) => http.get('/formtemplate/delete',{id})
export const GetByUserIdFormTemplate = (groupId) => http.get('/formtemplate/getByUserIdFormTemplate',{groupId})
export const Save = (params) => http.post('/formtemplate/save',params)
export const GetAllList = (domainId) => http.get('/formtemplate/allList',{domainId})
export const ClaimTemplate = (template) => http.post("/formtemplate/claim",template)
export const AllList = (name,domainId,page,pageSize = 10) => http.get('/formtemplate/getAllList',{name,domainId,page,pageSize})
export const GetDomainList = () => http.get('/formtemplate/getDomainList',{})

