<template>
  <div class=" mySign1">
    <img v-if="value" :src="value"   class="mySign" />
    <vue-esign v-else
     :height="500" :width="1000"
      ref="esign"
      :isCrop="false"
      :lineWidth="1"
      lineColor="#000000"
      bgColor.sync="bgColor"
    />
    <div style="display: block;clear: both;margin-top: 10px;">
      <template  v-if="value">
        <el-button type="primary"  size="medium" @click="updateSign">重新绘制</el-button>
      </template>
      <template v-else>
        <div style="color: #999;"><i class="el-icon-warning"></i> 绘制完成需手动点击“确定绘制”按钮保存画板内容</div>

    <el-button type="warning"  size="medium" @click="resetSign">清除内容</el-button>
    <el-button type="primary"  size="medium" @click="saveSignImg">确定绘制</el-button>
  </template>
    </div>
  </div>
</template>

<script>

export default {
	name: "XVueesign",
 data () {
    return {
      suffix:'',
     }
  },
  props:{
			value:String,
		},
    created(){
      this.suffix=parseInt(new Date().getTime() / 1000) + '';
    },
  mounted(){

  },
  methods: {
    resetSign(){
      this.$refs.esign.reset();

      },
      updateSign(){
        this.value="";
        this.$refs.esign.reset();
      },
      saveSignImg(){
      this.$refs.esign
        .generate()
        .then((res) => {
          this.value=res;
          this.$emit('input',this.value)
        })
        .catch((err) => {
          console.error(err)
          this.$message({
            type: "warning",
            message: "还没有任何内容",
          });
        });
      },
  }
}
</script>
<style >

.mySign1 canvas{
  border: 1px dashed #000;
}

</style>