<template>
	<div class="X__Upload">
		<div class="FruitUploaderContainer">
			<div v-if="isNotEmpty(files)" v-for="(item, index) in files" class="FileMask" :class="{'is-active':item.file_percentage > 0}" :key="item.uid">
				<el-progress v-if="item.file_percentage > 0" type="circle" :percentage="item.file_percentage" :width="120"></el-progress>
				<div v-else class="FileMask__content" :class="{'is-hover':item.is_hover}" @mouseenter="handleFileMouseEnter(item)" @mouseleave="handleFileMouseOut(item)">
					<div class="FileMask__title" v-if="item.title">{{ item.title }}</div>
					<div class="FileMask__title" v-else>{{ item.name }}</div>
					<div class="FileMask__meta">
						<div>上传时间</div>
						<div style="margin-top:5px">{{ item.create_time }}</div>
					</div>
					<div class="FileMask__actions">
						<i class="base-icon-delete" @click="handleFileRemoveV2(item)"></i>
					</div>
				</div>
			</div>
			<el-upload ref="FileUploader" class="FruitUploader FruitUploader--file" name="file1000" :data="{uploader:user.id}" :show-file-list="false" :headers="uploadHeaders" :with-credentials="true" :action="uploadAction" drag :file-list="files" :before-upload="handleBeforeFileUploadV2" :auto-upload="true" :on-success="handleFileUploadSuccessV2" :on-remove="handleFileRemoveV2" :on-error="handleFileUploadErrorV2" :on-preview="handleFilePreviewV2" :limit="5" :on-exceed="handleFileExceedV2" :on-progress="handleOnFileProgressV2">
				<i class="base-icon-plus"></i>
			</el-upload>
		</div>
		<div class="FruitUploader__tipItems">
			<div class="FruitUploader__tipItem">附件不限制文件类型，大小不超过50M</div>
			<div v-if="fileErrorMsg" class="FruitUploader__tipItem FruitUploader__tipItem--error">{{ fileErrorMsg }}</div>
		</div>
	</div>
</template>

<script>
  import { UPLOAD_ACTION } from '@/util/config'
export default {
	name: "XUpload",
	data(){
		return {
			maxUploadFileSize: 52428800,
			attachments: [],
			attachmentName: [],
			isAttachmentError: false,
			attachmentIndex: 0,
			fileErrorMsg: null,
			covers: [],
			coverPercentage: 0,
			coverUrl: null,
			coverMaskHover: false,
			files: [],
			fileName: [],
			uploadHeaders:{},
			uploadAction: UPLOAD_ACTION,
			isValueUpdate:false,
		}
	},
	props:{
		value:{
			type:Array,
			default:() => []
		}
	},
	created() {
		
	},
	mounted () {
		if(document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))){
		let CSRF_TOKEN = document.cookie.match(new RegExp(`XSRF-TOKEN=([^;]+)`))[1]
		this.uploadHeaders = {
			'X-XSRF-TOKEN': CSRF_TOKEN
		}
		}
	},
	watch:{
		files:function (newVal){
			if(newVal){
				if(!this.isValueUpdate){
					this.$emit('input',newVal.map(e => {
						return {
							id:e.uid,
							name:e.name,
							create_time:e.create_time,
							size:e.size,
							url:e.url
						}
					}))
				}else {
					this.isValueUpdate = false
				}
			}
		},
		value:function(newVal){
			if(newVal){
				this.files = this.value.map(e => {
					return {
						uid:e.id,
						name:e.name,
						size:e.size,
						percentage:100,
						//response:e.url,
						url:e.url,
						is_hover:false,
						file_percentage:0,
						create_time:e.create_time
					}
				});
				this.fileName = this.value.map(e => e.name)
				this.isValueUpdate = true
			}
		}
	},
	  mounted(){
			if(this.value&&this.value.length>0){
				this.files = this.value.map(e => {
					return {
						uid:e.id,
						name:e.name,
						size:e.size,
						percentage:100,
						//response:e.url,
						url:e.url,
						is_hover:false,
						file_percentage:0,
						create_time:e.create_time
					}
				});
			}
		},
	methods:{
		isNotEmpty (array) {
			return array && ((array.length > 0 && array.length !== 1) || (array.length === 1 && array[0]))
		},
		now () {
			let now = []
			let dateTime = new Date()
			let year = dateTime.getFullYear()
			now += year + '-'
			let month = dateTime.getMonth() + 1
			if (month < 10) {
				now += '0' + month + '-'
			} else {
				now += month + '-'
			}
			let date = dateTime.getDate()
			if (date < 10) {
				now += '0' + date + ' '
			} else {
				now += date + ' '
			}
			let hours = dateTime.getHours()
			if (hours < 10) {
				now += '0' + hours + ':'
			} else {
				now += hours + ':'
			}
			let minutes = dateTime.getMinutes()
			if (minutes < 10) {
				now += '0' + minutes
			} else {
				now += minutes
			}
			return now
		},
		handleBeforeFileUploadV2 (file) {
			this.isAttachmentError = false
			this.fileErrorMsg = null
			if (file.size === 0) {
				this.fileErrorMsg = '请选择有内容的文件'
				this.isAttachmentError = true
				return false
			}
			if (file.size > this.maxUploadFileSize) {
				this.fileErrorMsg = '请选择小于 50M 的文件'
				this.isAttachmentError = true
				return false
			}
			let duplicatedIndex = this.fileName.indexOf(file.name)
			if (duplicatedIndex !== -1) {
				let duplicatedFile = this.files[duplicatedIndex]
				if (duplicatedFile.size === file.size) {
					this.fileErrorMsg = '该文件已上传！'
					this.isAttachmentError = true
					return false
				}
			}
			this.fileName.push(file.name)
			//this.files.push(file)
		},
		handleFileRemoveV2 (file) {
			if (this.isAttachmentError) {
				return
			}
			this.$confirm('确定删除附件？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
				center: true
			}).then(() => {
				let index = this.fileName.indexOf(file.name)
				this.fileName.splice(index, 1)
				this.files.splice(index, 1)
			})
		},
		handleFileUploadSuccessV2 (response, file) {
			this.$message.success("上传成功")
			let index = this.fileName.indexOf(file.name)
			file.file_percentage = 0
			file.create_time = this.now()
			file.is_hover = false
			file.url = response.fastdfspath
			this.files.splice(index, 1, file)
		},
		handleFileUploadErrorV2 (err, file) {
			this.fileErrorMsg = '文件上传失败'
			this.handleFileRemoveV2(file)
		},
		handleFilePreviewV2 (file) {
			if (file.content) {
				DownloadFile(file.content, file.name)
			}
		},
		handleFileExceedV2 () {
			this.fileErrorMsg = '最多上传 5 个附件'
		},
		handleOnFileProgressV2 (event, file) {
			let percentage = Math.floor(event.percent)
			if (percentage > 99) {
				percentage = 99
			}
			let index = this.fileName.indexOf(file.name)
			file.file_percentage = percentage
			this.files.splice(index, 1, file)
		},
		handleFileMouseEnter (item) {
			if (item.file_percentage === 0) {
				let index = this.fileName.indexOf(item.name)
				item.is_hover = true
				this.$set(this.files, index, item)
			}
		},
		handleFileMouseOut (item) {
			if (item.file_percentage === 0) {
				let index = this.fileName.indexOf(item.name)
				item.is_hover = false
				this.$set(this.files, index, item)
			}
		},
		handleFileActionsSuccess (file) {
			let index = this.fileName.indexOf(file.name)
			let target = this.files[index]
			target.permission = file.permission
			target.title = file.title
			target.file_type = file.type
			target.category = file.category
			this.$set(this.files, index, target)
		},
		handleDownloadFile (file) {
			GetFileV2(file.url, file.name)
		},
	}
}
</script>

<style scoped lang="scss">
	@import "./ui/style/var";
	.FruitUploaderContainer {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		.FruitUploader {
			margin-bottom: 10px;
			&:first-child {
				margin-right: 10px;
			}
		}
		.CoverMask {
			width: 120px;
			height: 120px;
			position: absolute;
			background: $--color-white;
			img {
				border-radius: $--border-radius-base;
				cursor: pointer;
			}
			.CoverMask__actions {
				width: 120px;
				height: 120px;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				border-radius: $--border-radius-base;
				transition: all .3s;
				background-color: rgba(0,0,0,.5);
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: $--font-size-large;
				i {
					cursor: pointer;
				}
				i+i {
					margin-left: 16px;
				}
			}
			&.is-hover {
				.CoverMask__actions {
					opacity: 1;
				}
			}
		}
		.FileMask {
			width: 120px;
			height: 120px;
			background: $--background-color-base;
			border-radius: $--border-radius-base;
			margin-right: 10px;
			margin-bottom: 10px;
			&.is-active {
				background-color: transparent;
			}
		}
		.FileMask__content {
			width: calc(100% - 20px);
			height: calc(100% - 20px);
			padding: 10px;
			position: relative;
			.FileMask__actions {
				width: 120px;
				height: 120px;
				position: absolute;
				left: 0;
				top: 0;
				opacity: 0;
				border-radius: $--border-radius-base;
				transition: all .2s;
				background-color: rgba(0,0,0,.5);
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: $--font-size-large;
				i {
					cursor: pointer;
				}
				i+i {
					margin-left: 16px;
				}
			}
			&.is-hover {
				.FileMask__actions {
					opacity: 1;
				}
			}
		}
		.FileMask__title {
			font-size: $--font-size-small;
			line-height: 1.2;
			max-height: 64px;
			padding: 2px 0;
			overflow: hidden;
		}
		.FileMask__meta {
			position: absolute;
			bottom: 10px;
			line-height: 1.2;
			font-size: $--font-size-extra-small;
			color: $--color-text-secondary;
		}
		.FruitUploader {
			width: 120px;
			height: 120px;
		}
	}
	.FruitUploader__tipItems {
		line-height: 1.2;
	}
	.FruitUploader__tipItem {
		font-size: $--font-size-small;
		color: $--color-text-secondary;
	}
	.FruitUploader__tipItem+.FruitUploader__tipItem {
		margin-top: 4px;
	}
	.FruitUploader__tipItem--error {
		color: $--color-danger;
	}
</style>
<style>
	.X__Upload .base-upload-dragger{width: 120px;height: 120px;display: flex;align-items: center;justify-content: center;font-size: 26px;}
</style>