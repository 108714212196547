<template>
  <!-- ckeditor 父元素 -->
  <div class="ck-editor__editable">
    <!-- 编辑器 -->
    <textarea  :name="'ckeditorData'+suffix" class="ckeditor">
  </textarea>
  </div>
</template>

<script>

export default {
	name: "XCkediter",
 data () {
    return {
      ckEditor :null,
      suffix:'',
      ckEditorContent:'',
     }
  },
  props:{
			value:String
		},
    created(){
      this.suffix=this.generateUUID();
    },
  mounted(){
    this.ckEditor =window.CKEDITOR.replace('ckeditorData'+this.suffix, {
      toolbar: "Full" ,
      placeholder: '请输入...'
    })
    if(this.ckEditor){
     this.ckEditor.setData(this.value)
      this.ckEditor.on('change', () => {
        this.ckEditorContent = this.ckEditor.getData()
        this.$emit('input',this.ckEditorContent)
      })
    }
  },
  methods: {
    generateUUID() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0,
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    }
  }
}
</script>
<style >
  /*编辑框最低高度*/
  .ck-editor__editable{
    min-height: 300px;
  }
  .cke_button__ckeditor_wiris_formulaeditor_icon, .cke_button__ckeditor_wiris_formulaeditorchemistry_icon{
    width: 70px !important;
    background-size: 100% 100% !important;
  }

  /**强制ckidtertoolbar不换行 */
  .cke_toolbar_break{
    display: inherit !important;
  }
  .ck-editor__editable .cke_combo_text{
    width: 26px !important;
  }
  .ck-editor__editable .cke_contents{
    height: 400px !important;
  }
  /** .ck-editor__editable #cke_59{
    display: none  !important;

  }
  .cke_toolbar:last-child {
    display: none;
}*/
  .cke_bottom{
    display: none  !important;
  }
#FMathEd1_ads{
    display: none !important;
  }
</style>