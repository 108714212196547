import Vue from 'vue'
import dayjs from 'dayjs'
import zh from 'dayjs/locale/zh-cn'
import relativeTime from 'dayjs/plugin/relativeTime'
import { AppMixins } from "../mixins"
import BaseUI from '../components/base/ui'
import AppDialog from '../components/base/widget/AppDialog.vue'
import Avatar1 from '../components/base/Avatar.vue'
import XHeader from '../components/busiess/XHeader.vue';

import XContent from "../components/busiess/XContent.vue"
import {Empty,Timeline,TimelineItem,Skeleton,SkeletonItem,Table,TableColumn,Breadcrumb,BreadcrumbItem,Badge,Link,Transfer,Card,Avatar,Container,
    Header,
    Aside,
    Main,
    Footer,Popconfirm,Slider,InputNumber,Rate,Tree,Descriptions,DescriptionsItem} from "element-ui";
    //import elementUi from "element-ui";
import XCard from "../components/busiess/XCard.vue"
import BounceLoading from "@/components/base/widget/BounceLoading";
import './element_ui.scss';
import XUpload from "@/components/base/XUpload";
import XKetcher from "@/components/base/XKetcher";
import XCkeditor from "@/components/base/XCkeditor";
import XVditor from "@/components/base/XVditor";
import XVueesign from "@/components/base/XVueesign";

import ActionPanel from '@/components/support/ActionPanel.vue'
import vueEsign from 'vue-esign'
export const initVueConfig = () => {
    Vue.use(vueEsign);
    dayjs.locale(zh)
    dayjs.extend(relativeTime)
    Vue.mixin(AppMixins);
    //Vue.use(elementUi);
    Vue.use(BaseUI)
   Vue.component('ElContainer',Container);
    Vue.component('ElHeader',Header);
    Vue.component('ElAside',Aside);
    Vue.component('ElPopconfirm',Popconfirm);
    Vue.component('ElMain',Main);
    Vue.component('ElFooter',Footer);
    Vue.component('ElEmpty',Empty);
    Vue.component('ElSlider',Slider);
    Vue.component('ElTimeline',Timeline);
    Vue.component('ElTimelineItem',TimelineItem);
    Vue.component('ElSkeleton',Skeleton);
    Vue.component('ElSkeletonItem',SkeletonItem);
    Vue.component('ElContainer',Container);
    Vue.component('ElTable',Table);
    Vue.component('ElTableColumn',TableColumn);
    Vue.component('ElBreadcrumb',Breadcrumb);
    Vue.component('ElBreadcrumbItem',BreadcrumbItem);
    Vue.component('ElBadge',Badge);
    Vue.component('ElLink',Link);
    Vue.component('ElTransfer',Transfer);
    Vue.component('ElAvatar',Avatar)
    Vue.component('ElCard',Card)
    Vue.component('ElRate',Rate)
    Vue.component('ElInputNumber',InputNumber)
    Vue.component('ElTree',Tree)
    Vue.component('ElDescriptions',Descriptions)
    Vue.component('ElDescriptionsItem',DescriptionsItem)

    
    
    Vue.component('XCard',XCard)
    Vue.component('XContent',XContent)
    Vue.component('XHeader',XHeader)
    Vue.component('Avatar',Avatar1)
    Vue.component('XUpload',XUpload)
    Vue.component('XKetcher',XKetcher)
    Vue.component('XCkeditor',XCkeditor)
    Vue.component('XVditor',XVditor)
    Vue.component('XVueesign',XVueesign)

    
    Vue.component('AppDialog',AppDialog)
    Vue.component('ActionPanel',ActionPanel)
    Vue.component('BounceLoading',BounceLoading)
}

