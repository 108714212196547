import axios from 'axios'
import qs from 'qs'
import {baseURL} from './config.js'
import {GetStorage} from './cache'
import {STORAGE} from '../constants/index'

axios.interceptors.request.use(config => {
    let tokenName = '';
    let tokenValue = '';
    let token = GetStorage(STORAGE.TOKEN);
    if(token){
        tokenName = token.tokenName || '';
        tokenValue = token.tokenValue || '';
        config.headers[tokenName] = tokenValue;
    }
    let domain=window.location.hostname
    config.headers['domain'] = domain;
    return config
  }, error => {
    return Promise.reject(error)
  })


export default {
    get(url, params, config) {
        return new Promise((resolve, reject) => {
            axios.get(url, {
                params: params,
                baseURL: config?.baseURL ?? baseURL ?? '', 
                timeout: 100000
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    post(url, params, config) {
        return new Promise((resolve, reject) => {
            axios.post(url, qs.stringify(params), {
                baseURL: config?.baseURL ?? baseURL ?? '',
                timeout: 100000,
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    delete(url, params, config) {
        return new Promise((resolve, reject) => {
            axios.delete(url, { 
                params: params,
                baseURL: config?.baseURL ?? baseURL ?? '',
                timeout: 100000
            }).then(response => {
                    resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    put(url, params, config) {
        return new Promise((resolve, reject) => {
            axios.put(url, { 
                params: params,
                baseURL: config?.baseURL ?? baseURL ?? '',
                timeout: 100000,
            }).then(response => {
                resolve(response.data)
            }) .catch(error => {
                reject(error)
            })
        })
    },
    postJSON(url, params, config) {
        return new Promise((resolve, reject) => {
            axios.post(url, params, {
                baseURL:  config?.baseURL ?? baseURL ?? '',
                timeout: 100000,
                headers: {
                    'Content-Type': 'application/json'  //如果写成contentType会报错
                }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    upload(url, params, config) {
        let fd = new FormData()
        for (var key in params) {
            fd.append(key, params[key]);
        }
        return new Promise((resolve, reject) => {
            axios.post(url, fd, {
                baseURL:  config?.baseURL ?? baseURL ?? '',
                timeout: 100000,
                headers: { 'Content-Type': 'multipart/form-data' }
            }).then(response => {
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    }
}
