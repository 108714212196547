import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './router'
import store from './store'
import { initVueConfig } from './config'
import {domainConfigDataByDomain} from "./service/domainConfig"



export var appid = '';
export var secret = '';
export var redirectUri = '';
export var quitRedirectUri = '';
export var institutionId='';
if(process.env.NODE_ENV == 'development'){
    appid = 'hkyp5daekae39cb9mj';
    secret = 'vk0filph1wauil25k4rw3ggt1vk013kz';
    redirectUri = 'http://localhost:8081/loadLogin';
    quitRedirectUri = 'http://localhost:8081';
}else{
    //appid = 'hky70nk7meofsvzqqj';
    //secret = 'gb11pxvvn4pase6wyi3l11eiaup2ldzr';
    //redirectUri = 'http://159.226.102.87/loadLogin';
    //quitRedirectUri = 'http://159.226.102.87';
    appid = 'hkyv52goz5w5lfwowg';
    secret = 'ervomlfs96yg3o0gc13vkvs9qrs4dmc4';
    redirectUri = 'https://lablink.ac.cn/loadLogin';
    quitRedirectUri = 'https://lablink.ac.cn';
}
let domain=window.location.origin;
domainConfigDataByDomain(domain).then(e => {
    if(e.code==0&&e.data){
        let data=e.data
    if(data){
        appid=data.appid;
        secret=data.secret;
        redirectUri=data.redirectUri;
        quitRedirectUri=data.quitRedirectUri;
        institutionId=data.institutionId;
    }
}
    Vue.config.productionTip = false
    Vue.prototype.window = window;
    Vue.use(VueRouter);

    initVueConfig();
    var router = new VueRouter({
        routes,
        mode: 'history',
        afterEach (to, from, next) {
            window.scrollTo(0, 0)
        }
    })
    var vue = new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');
  })