<template>
	<div class="XKetcher__warp">
		<iframe ref="ketcherFrame" src="/static/ketcher/index.html"></iframe>
	</div>
</template>

<script>
	export default {
		name: "XKetcher",
		data(){
			return {
				data:{
					mol:'',
					png:''
				},
				isMeUpdate:false,
				myTime:null
			}
		},
		props:{
			value:{
				type:Object,
				default:() => {
					return {
						mol:'',
						png:''
,					}
				}
			}
		},
		watch:{
			value:{
				handler:function (newVal){
					if(newVal){
						if(!this.isMeUpdate){
							this.setData(newVal.mol)
							this.$set(this,'data',newVal)
							this.isMeUpdate = false
						}
					}
				},
				immediate:true
			}
		},
		mounted() {
			this.myTime = setInterval(() => {
				this.getData();
			},1000)
		},
		unmounted(){
			if(this.myTime){
				clearInterval(this.myTime)
			}
		},
		methods:{
			getData(){
				if(this.$refs.ketcherFrame&&this.$refs.ketcherFrame.contentWindow&&this.$refs.ketcherFrame.contentWindow.ketcher){
					this.$refs.ketcherFrame.contentWindow.ketcher.getMolfile().then(mol => {
						if(this.data.mol != mol){
							this.$refs.ketcherFrame.contentWindow.ketcher.generateImage(mol).then(png => {
								this.blobToDataURI(png,(base64) => {
									this.$set(this,'data',{
										mol:mol,
										png:base64
									})
									this.isMeUpdate = true;
									this.$emit('input',this.data)
								})
							})
						}
					})
				}
			},
			blobToDataURI(blob, callback) {
				var reader = new FileReader();
				reader.onload = function (e) {
					callback(e.target.result);
				}
				reader.readAsDataURL(blob);
			},
			setData(mol){
				if(mol){
					let _this = this;
					this.$nextTick(() => {
						this.$refs.ketcherFrame.onload = function(){
							setTimeout(() => {
								_this.$refs.ketcherFrame.contentWindow.ketcher.setMolecule(mol)
							}, 50);
						}
					})
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.XKetcher__warp{
		width: 100%;height: 100%;
		iframe{width: 100%;height: 100%;border: none;;min-height: 600px;}
	}
</style>