<template>
  <div class="base-button-group">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'BaseButtonGroup'
}
</script>
